import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';

var imagens = [
    ["./assets/diferenciais/angelica/1.jpg", "./assets/diferenciais/angelica/2.jpg", "./assets/diferenciais/angelica/3.jpg",],
    ["./assets/diferenciais/aidil/1.jpg", "./assets/diferenciais/aidil/2.jpg",],
    ["./assets/diferenciais/gourmet/1.jpg", "./assets/diferenciais/gourmet/2.jpg", "./assets/diferenciais/gourmet/3.jpg", "./assets/diferenciais/gourmet/4.jpg",],
    ["./assets/diferenciais/lazer/1.jpg", "./assets/diferenciais/lazer/2.jpg",],
];

export default function Portugues() {
    // ####### NAVBAR ########
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [drop, setDrop] = useState(false);
    const dropdownContentRef = useRef(null);
    const navbarRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        // Limpar o event listener quando o componente for desmontado
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if ((dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) && !navbarRef.current.contains(event.target)) {
                setDrop(false);
            }
        }

        if (drop) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [drop]); // Só re-executa o efeito se o valor de 'drop' mudar

    // ####### FORM ########
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [dataFimMin, setDataFimMin] = useState('');
    const [hospedes, setHospedes] = useState(null);

    useEffect(() => {
        const hoje = new Date();
        const dataFormatada = hoje.toISOString().split('T')[0];
        setDataInicio(dataFormatada); // Define a data de hoje como valor inicial para dataInicio
        setDataFimMin(dataFormatada); // Define a data mínima para dataFim
    }, []);

    const handleDataInicioChange = (event) => {
        const novaDataInicio = event.target.value;
        setDataInicio(novaDataInicio);

        if (novaDataInicio) {
            const data = new Date(novaDataInicio);
            data.setDate(data.getDate() + 2);
            const novaDataFimMin = data.toISOString().split('T')[0];
            setDataFimMin(novaDataFimMin);
        }
    };

    const handleHospedesChange = (evt) => {
        setHospedes(evt.target.value);
    }

    const searchButton = (evt) => {
        evt.preventDefault();
        window.open(`https://www.airbnb.com.br/book/stays/1067473187464322078?checkin=${dataInicio}&numberOfGuests=${hospedes}&numberOfAdults=${hospedes}&checkout=${dataFim}&guestCurrency=BRL&productId=1067473187464322078&isWorkTrip=false&numberOfChildren=0&numberOfInfants=0&numberOfPets=0&selectedCancellationPolicyId=51&code=HMBH84AQJA&orderId=1071119166320408747`, '_blank')
    }

    const whatsappButton = (evt) => {
        evt.preventDefault();
        window.open("https://wa.me/558282074393", '_blank')
    }

    // ####### MODAL ########
    const [showModal, setShowModal] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [slideIndex, setSlideIndex] = useState(1);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.className === 'modal') {
                setShowModal(false);
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const openModal = (id) => {
        setCurrentImages(imagens[id]);
        setSlideIndex(1);
        setShowModal(true);
    };

    const changeSlide = (n) => {
        let newIndex = slideIndex + n;
        if (newIndex < 1) {
            newIndex = currentImages.length;
        } else if (newIndex > currentImages.length) {
            newIndex = 1;
        }
        setSlideIndex(newIndex);
    };

    return (
        <>
            <div class="background"></div>
            <div class="overlay"></div>

            <nav class="navbar" ref={navbarRef}>
                <Link class="nav-logo scrollLink" to="background"
                    smooth={true} duration={500} offset={-75}
                ><img class="nav-logo-img" src="./assets/nome.svg" alt="logo"
                    /></Link>
                {windowWidth > 900 ? (
                    <div class="nav-options">
                        <ul class="nav-options">
                            <li><Link class="scrollLink" to="reservas" smooth={true} duration={500} offset={-75}>RESERVAS</Link></li>
                            <li><Link class="scrollLink" to="o-espaco" smooth={true} duration={500} offset={-75}>O ESPAÇO</Link></li>
                            <li><Link class="scrollLink" to="diferenciais" smooth={true} duration={500} offset={-75}>DIFERENCIAIS</Link></li>
                            <li><Link class="scrollLink" to="massagueira" smooth={true} duration={500} offset={-75}>MASSAGUEIRA</Link></li>
                            <li><Link class="scrollLink" to="contato" smooth={true} duration={500} offset={-75}>CONTATO</Link></li>
                        </ul>
                        <div class="language-selector"><a href="/en">EN</a><div>•</div><a href="/es">ES</a></div>
                    </div>
                ) : (
                    <button class="nav-btn" onClick={() =>  setDrop(currentDrop => !currentDrop)}><img src="./assets/plus.svg" alt="expand btn" /></button>
                )}
            </nav>

            {drop ? (
                <div class="dropdown" ref={dropdownContentRef}>
                    <ul class="nav-options">
                        <li><Link class="scrollLink" to="reservas" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>RESERVAS</Link></li>
                        <li><Link class="scrollLink" to="o-espaco" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>O ESPAÇO</Link></li>
                        <li><Link class="scrollLink" to="diferenciais" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>DIFERENCIAIS</Link></li>
                        <li><Link class="scrollLink" to="massagueira" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>MASSAGUEIRA</Link></li>
                        <li><Link class="scrollLink" to="contato" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>CONTATO</Link></li>
                        <li class='drop-lselect'><a href="/en">EN</a><div>|</div><a href="/es">ES</a></li>
                    </ul>
                </div>
            ) : (<></>)}

            <div class="home" id="background"></div>

            {showModal && (
                <div className="modal" style={{ display: 'flex' }}>
                    <div className='modal-content'>
                        <div className="slideshow-container">
                            {currentImages.map((src, index) => (
                                <div key={index} className="mySlides fade" style={{ display: slideIndex === index + 1 ? 'flex' : 'none' }}>
                                    <img className="mySlides-img" src={src} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                            <button class="prev" onClick={() => changeSlide(-1)}>&#10094;</button>
                            <button class="next" onClick={() => changeSlide(1)}>&#10095;</button>
                        </div>
                    </div>
                </div>
            )}

            <div class="form" id="reservas">
                RESERVE ONLINE
                <form class="f-online" onSubmit={searchButton}>
                    <div class="inputs-container">
                        <div class="col-6">
                            <label for="inicio">Início</label>
                            <input
                                type="date"
                                class="data"
                                name="inicio"
                                id="inicio"
                                min={dataInicio}
                                onChange={handleDataInicioChange}
                                required
                            />
                        </div>

                        <div class="col-6">
                            <label for="fim">Final</label>
                            <input type="date" class="data" name="fim" id="fim" min={dataFimMin} disabled={!dataInicio} onChange={(e) => setDataFim(e.target.value)} required />
                        </div>

                        <div class="col-12">
                            <label for="hospedes">Hóspedes</label>
                            <input
                                type="number"
                                name="hospedes"
                                min={1}
                                max={19}
                                placeholder="15"
                                value={hospedes}
                                onChange={handleHospedesChange}
                                required
                            />
                        </div>
                    </div>

                    <div class="botao-online">
                        <button type='submit'>PESQUISAR</button>
                    </div>
                </form>

                {/* RESERVE CONOSCO
                <form class="f-whatsapp" onSubmit={whatsappButton}>
                    <button type="submit">
                        CANAL DE RESERVAS <img src="./assets/icons/zapzap.png" alt="whatsapp" />
                    </button>
                </form> */}
            </div>

            <div class="o-espaco" id="o-espaco">
                <div class="titulo">O ESPAÇO</div>
                <div class="o-espaco-1">
                    <img
                        class="o-espaco-foto"
                        src="./assets/o-espaco/1.jpg"
                        alt="espaço gouvea"
                    />
                    <p>
                        Situado a 10km de Maceió e a apenas 5km da deslumbrante Praia do
                        Francês, nosso refúgio de 4500m2 é uma fusão perfeita de conforto e
                        natureza. Assinado por <a className='agra-lemos' href='https://agralemos.com.br' target='_blank' rel="noreferrer">Agra Lemos Arquitetos</a>, o espaço conta com duas
                        casas incríveis com 7 suítes confortáveis, acomodando até 20 pessoas.
                    </p>
                </div>

                <div class="o-espaco-2">
                    <img
                        class="o-espaco-foto"
                        src="./assets/o-espaco/2.jpg"
                        alt="espaço gouvea"
                    />
                    <p>
                        Ideal para confraternizar com familiares e amigos, nosso espaço recém-renovado promete momentos
                        mágicos e memórias eternas em um ambiente de serenidade e beleza.
                        Desfrute do charme, privacidade e da conexão com a natureza em um dos
                        destinos mais encantadores.
                    </p>
                </div>
            </div>

            <div class="diferenciais" id="diferenciais">
                <div class="titulo">DIFERENCIAIS</div>
                <div class="grid">
                    <div class="diferencial">
                        <img src="./assets/diferenciais/poste.png" alt="casa angelica" />
                        <div class="subtitulo">CASA ANGÉLICA</div>
                        <p>
                            Esta casa principal conta com 3 suítes amplas com varandas,
                            aconchegante sala de estar com jantar e cozinha aberta integrados,
                            cozinha de apoio com lavanderia e dependência, além de um
                            convidativo espaço externo.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(0)}>saiba mais</button>
                    </div>
                    <div class="diferencial">
                        <img src="./assets/diferenciais/aidil.png" alt="casa aidil" />
                        <div class="subtitulo">CASA AIDIL</div>
                        <p>
                            Esta encantadora casa secundária oferece 4 suítes adicionais,
                            proporcionando privacidade e conforto, complementada por um belo
                            espaço externo.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(1)}>saiba mais</button>
                    </div>
                    <div class="diferencial">
                        <img
                            src="./assets/diferenciais/espacogourmet.png"
                            alt="espaco gourmet"
                        />
                        <div class="subtitulo">ESPAÇO GOURMET</div>
                        <p>
                            Este ambiente perfeito para socializar conta com espaço gourmet,
                            churrasqueira moderna e um espaço social acolhedor.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(2)}>saiba mais</button>
                    </div>
                    <div class="diferencial">
                        <img src="./assets/diferenciais/lazer.png" alt="lazer" />
                        <div class="subtitulo">LAZER</div>
                        <p>
                            Uma imponente piscina com hidromassagem integrada, iluminação de LED
                            e um relaxante deck de madeira equipado com espreguiçadeiras. Além
                            disso, para os entusiastas do esporte, oferecemos um campo de grama
                            natural de 35x20m, ideal para partidas amistosas.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(3)}>saiba mais</button>
                    </div>
                </div>
            </div>

            <div class="massagueira" id="massagueira">
                <div class="titulo">MASSAGUEIRA</div>
                <div class="grid">
                    <iframe
                        src="https://www.youtube.com/embed/tBA_bkSbM7M?si=K9mO4cXZnSc449xr"
                        title="YouTube video player"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                    <p>
                        Estamos na Massagueira, bairro famoso por seus restaurantes à beira da
                        lagoa, que oferecem uma experiência gastronômica inigualável com
                        frutos do mar frescos, pescados diretamente das águas locais. O
                        cenário ao redor, com vistas da lagoa cintilante, complementa a
                        experiência, tornando cada refeição uma ocasião memorável. Além da
                        culinária, o bairro é uma tapeçaria de ruas tranquilas e espaços
                        acolhedores, refletindo o charme e a hospitalidade da região.
                    </p>
                </div>
            </div>

            <div class="contato" id="contato">
                <div class="titulo">CONTATO</div>
                <div class="dados">
                    {/* <div class="reservas">
                        <img src="./assets/icons/celular.png" alt="telefone" /> <b>Reservas</b> <a target="_blank" href="https://wa.me/558282074393" rel="noreferrer"
                        >+55 (82) 98207-4393</a>
                    </div> */}
                    <div class="localizacao">
                        <img src="./assets/icons/pin.png" alt="localizacao" /> <a target="_blank" href="https://maps.app.goo.gl/rUX7h7EbkYUx7F7RA" rel="noreferrer" >Av. Nossa Sra. da Conceição 263, Marechal Deodoro - AL,
                            57160-000</a>
                    </div>
                </div>
                <div class="social">
                    <a target="_blank" href="https://airbnb.com/h/espacogouvea" rel="noreferrer"
                    ><img src="./assets/icons/airbnb.png" alt="airbnb"
                        /></a>
                    <a target="_blank" href="https://instagram.com/espacogouvea" rel="noreferrer"
                    ><img src="./assets/icons/instagram.png" alt="instagram"
                        /></a>
                    <a target="_blank" href="https://g.co/kgs/rJBiWAv" rel="noreferrer"
                    ><img src="./assets/icons/tripadvisor.png" alt="tripadvisor"
                        /></a>
                </div>
                <div class="creditos">
                    <a href="https://caioagralemos.com"
                    >© Caio Agra Lemos. All rights reserved</a>
                </div>
            </div>
        </>
    )
}