import RoutesComponent from "./routes";
import './styles/style.css'

function App() {
  return (
    <RoutesComponent />
  );
}

export default App;
