import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';

var imagens = [
    ["./assets/diferenciais/angelica/1.jpg", "./assets/diferenciais/angelica/2.jpg", "./assets/diferenciais/angelica/3.jpg",],
    ["./assets/diferenciais/aidil/1.jpg", "./assets/diferenciais/aidil/2.jpg",],
    ["./assets/diferenciais/gourmet/1.jpg", "./assets/diferenciais/gourmet/2.jpg", "./assets/diferenciais/gourmet/3.jpg", "./assets/diferenciais/gourmet/4.jpg",],
    ["./assets/diferenciais/lazer/1.jpg", "./assets/diferenciais/lazer/2.jpg",],
];

export default function Espanhol() {
    // ####### NAVBAR ########
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [drop, setDrop] = useState(false);
    const dropdownContentRef = useRef(null);
    const navbarRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        // Limpar o event listener quando o componente for desmontado
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if ((dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) && !navbarRef.current.contains(event.target)) {
                setDrop(false);
            }
        }

        if (drop) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [drop]); // Só re-executa o efeito se o valor de 'drop' mudar

    // ####### FORM ########
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [dataFimMin, setDataFimMin] = useState('');
    const [hospedes, setHospedes] = useState(null);

    useEffect(() => {
        const hoje = new Date();
        const dataFormatada = hoje.toISOString().split('T')[0];
        setDataInicio(dataFormatada); // Define a data de hoje como valor inicial para dataInicio
        setDataFimMin(dataFormatada); // Define a data mínima para dataFim
    }, []);

    const handleDataInicioChange = (event) => {
        const novaDataInicio = event.target.value;
        setDataInicio(novaDataInicio);

        if (novaDataInicio) {
            const data = new Date(novaDataInicio);
            data.setDate(data.getDate() + 2);
            const novaDataFimMin = data.toISOString().split('T')[0];
            setDataFimMin(novaDataFimMin);
        }
    };

    const handleHospedesChange = (evt) => {
        setHospedes(evt.target.value);
    }

    const searchButton = (evt) => {
        evt.preventDefault();
        window.open(`https://www.airbnb.com.br/book/stays/1067473187464322078?checkin=${dataInicio}&numberOfGuests=${hospedes}&numberOfAdults=${hospedes}&checkout=${dataFim}&guestCurrency=BRL&productId=1067473187464322078&isWorkTrip=false&numberOfChildren=0&numberOfInfants=0&numberOfPets=0&selectedCancellationPolicyId=51&code=HMBH84AQJA&orderId=1071119166320408747`, '_blank')
    }

    const whatsappButton = (evt) => {
        evt.preventDefault();
        window.open("https://wa.me/558282074393", '_blank')
    }

    // ####### MODAL ########
    const [showModal, setShowModal] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [slideIndex, setSlideIndex] = useState(1);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.className === 'modal') {
                setShowModal(false);
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const openModal = (id) => {
        setCurrentImages(imagens[id]);
        setSlideIndex(1);
        setShowModal(true);
    };

    const changeSlide = (n) => {
        let newIndex = slideIndex + n;
        if (newIndex < 1) {
            newIndex = currentImages.length;
        } else if (newIndex > currentImages.length) {
            newIndex = 1;
        }
        setSlideIndex(newIndex);
    };

    return (
        <>
            <div class="background"></div>
            <div class="overlay"></div>

            <nav class="navbar" ref={navbarRef}>
                <Link class="nav-logo scrollLink" to="background"
                    smooth={true} duration={500} offset={-75}
                ><img class="nav-logo-img" src="./assets/nome.svg" alt="logo"
                    /></Link>
                {windowWidth > 900 ? (
                    <div class="nav-options">
                        <ul class="nav-options">
                            <li><Link class="scrollLink" to="reservas" smooth={true} duration={500} offset={-75}>RESERVAS</Link></li>
                            <li><Link class="scrollLink" to="o-espaco" smooth={true} duration={500} offset={-75}>ESPACIO</Link></li>
                            <li><Link class="scrollLink" to="diferenciais" smooth={true} duration={500} offset={-75}>DIFERENCIALES</Link></li>
                            <li><Link class="scrollLink" to="massagueira" smooth={true} duration={500} offset={-75}>MASSAGUEIRA</Link></li>
                            <li><Link class="scrollLink" to="contato" smooth={true} duration={500} offset={-75}>CONTACTO</Link></li>
                        </ul>
                        <div class="language-selector"><a href="/">PT</a><div>•</div><a href="/en">EN</a></div>
                    </div>
                ) : (
                    <button class="nav-btn" onClick={() => setDrop(!drop)}><img src="./assets/plus.svg" alt="expand btn" /></button>
                )}
            </nav>

            {drop ? (
                <div class="dropdown" ref={dropdownContentRef}>
                    <ul class="nav-options">
                        <li><Link class="scrollLink" to="reservas" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>RESERVAS</Link></li>
                        <li><Link class="scrollLink" to="o-espaco" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>ESPACIO</Link></li>
                        <li><Link class="scrollLink" to="diferenciais" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>DIFERENCIALES</Link></li>
                        <li><Link class="scrollLink" to="massagueira" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>MASSAGUEIRA</Link></li>
                        <li><Link class="scrollLink" to="contato" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>CONTACTO</Link></li>
                        <li class='drop-lselect'><a href="/">PT</a><div>|</div><a href="/en">EN</a></li>
                    </ul>
                </div>
            ) : (<></>)}

            <div class="home" id="background"></div>

            {showModal && (
                <div className="modal" style={{ display: 'flex' }}>
                    <div className='modal-content'>
                        <div className="slideshow-container">
                            {currentImages.map((src, index) => (
                                <div key={index} className="mySlides fade" style={{ display: slideIndex === index + 1 ? 'flex' : 'none' }}>
                                    <img className="mySlides-img" src={src} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                            <button class="prev" onClick={() => changeSlide(-1)}>&#10094;</button>
                            <button class="next" onClick={() => changeSlide(1)}>&#10095;</button>
                        </div>
                    </div>
                </div>
            )}

            <div class="form" id="reservas">
                RESERVE EN LÍNEA
                <form class="f-online" onSubmit={searchButton}>
                    <div class="inputs-container">
                        <div class="col-6">
                            <label for="inicio">Inicio</label>
                            <input
                                type="date"
                                class="data"
                                name="inicio"
                                id="inicio"
                                min={dataInicio}
                                onChange={handleDataInicioChange}
                                required
                            />
                        </div>

                        <div class="col-6">
                            <label for="fim">Final</label>
                            <input type="date" class="data" name="fim" id="fim" min={dataFimMin} disabled={!dataInicio} onChange={(e) => setDataFim(e.target.value)} required />
                        </div>

                        <div class="col-12">
                            <label for="hospedes">Invitados</label>
                            <input
                                type="number"
                                name="hospedes"
                                min={1}
                                max={19}
                                placeholder="15"
                                value={hospedes}
                                onChange={handleHospedesChange}
                                required
                            />
                        </div>
                    </div>

                    <div class="botao-online">
                        <button type="submit">BUSCAR</button>
                    </div>
                </form>

                {/* RESERVE CON NOSOTROS
                <form class="f-whatsapp" onSubmit={whatsappButton}>
                    <button type="submit">
                        CANAL DE RESERVAS <img src="./assets/icons/zapzap.png" alt="whatsapp" />
                    </button>
                </form> */}
            </div>

            <div class="o-espaco" id="o-espaco">
                <div class="titulo">ESPACIO</div>
                <div class="o-espaco-1">
                    <img
                        class="o-espaco-foto"
                        src="./assets/o-espaco/1.jpg"
                        alt="espaço gouvea"
                    />
                    <p>
                        Situado a 10 km de Maceió y a solo 5 km de la impresionante Playa del
                        Francés, nuestro refugio de 4500 m² es una fusión perfecta de confort y
                        naturaleza. Diseñado por <a className='agra-lemos' href='https://agralemos.com.br' target='_blank' rel="noreferrer">Agra Lemos Arquitetos</a>, el espacio cuenta con dos
                        casas increíbles con 7 suites confortables, acomodando hasta 20 personas.
                    </p>
                </div>

                <div class="o-espaco-2">
                    <img
                        class="o-espaco-foto"
                        src="./assets/o-espaco/2.jpg"
                        alt="espaço gouvea"
                    />
                    <p>
                        Ideal para socializar con familiares y amigos, nuestro espacio recién renovado promete momentos
                        mágicos y recuerdos eternos en un entorno de serenidad y belleza.
                        Disfrute del encanto, privacidad y la conexión con la naturaleza en uno de
                        los destinos más encantadores.
                    </p>
                </div>
            </div>

            <div class="diferenciais" id="diferenciais">
                <div class="titulo">DIFERENCIALES</div>
                <div class="grid">
                    <div class="diferencial">
                        <img src="./assets/diferenciais/poste.png" alt="casa angelica" />
                        <div class="subtitulo">CASA ANGÉLICA</div>
                        <p>
                            Esta casa principal cuenta con 3 suites amplias con balcones,
                            acogedora sala de estar con comedor y cocina abierta integrados,
                            cocina de apoyo con lavandería y dependencia, además de un
                            espacio externo atractivo.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(0)}>saber más</button>
                    </div>
                    <div class="diferencial">
                        <img src="./assets/diferenciais/aidil.png" alt="casa aidil" />
                        <div class="subtitulo">CASA AIDIL</div>
                        <p>
                            Esta encantadora casa secundaria ofrece 4 suites adicionales,
                            proporcionando privacidad y confort, complementada por un hermoso
                            espacio externo.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(1)}>saber más</button>
                    </div>
                    <div class="diferencial">
                        <img
                            src="./assets/diferenciais/espacogourmet.png"
                            alt="espaco gourmet"
                        />
                        <div class="subtitulo">ESPACIO GOURMET</div>
                        <p>
                            Este ambiente perfecto para socializar cuenta con espacio gourmet,
                            barbacoa moderna y un espacio social acogedor.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(2)}>saber más</button>
                    </div>
                    <div class="diferencial">
                        <img src="./assets/diferenciais/lazer.png" alt="lazer" />
                        <div class="subtitulo">OCIO</div>
                        <p>
                            Una imponente piscina con hidromasaje integrado, iluminación LED
                            y un relajante deck de madera equipado con tumbonas. Además,
                            para los entusiastas del deporte, ofrecemos un campo de césped
                            natural de 35x20m, ideal para partidos amistosos.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(3)}>saber más</button>
                    </div>
                </div>
            </div>

            <div class="massagueira" id="massagueira">
                <div class="titulo">MASSAGUEIRA</div>
                <div class="grid">
                    <iframe
                        src="https://www.youtube.com/embed/tBA_bkSbM7M?si=K9mO4cXZnSc449xr"
                        title="YouTube video player"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                    <p>
                        Estamos en Massagueira, barrio famoso por sus restaurantes a orillas del
                        lago, que ofrecen una experiencia gastronómica incomparable con
                        mariscos frescos, pescados directamente de las aguas locales. El
                        paisaje alrededor, con vistas del lago centelleante, complementa la
                        experiencia, convirtiendo cada comida en una ocasión memorable. Más allá
                        de la gastronomía, el barrio es una mezcla de calles tranquilas y espacios
                        acogedores, reflejando el encanto y la hospitalidad de la región.
                    </p>
                </div>
            </div>

            <div class="contato" id="contato">
                <div class="titulo">CONTACTO</div>
                <div class="dados">
                    {/* <div class="reservas">
                        <img src="./assets/icons/celular.png" alt="telefono" /> <b>Reservas</b> <a target="_blank" href="https://wa.me/558282074393" rel="noreferrer"
                        >+55 (82) 98207-4393</a>
                    </div> */}
                    <div class="localizacao">
                        <img src="./assets/icons/pin.png" alt="ubicación" /> <a target="_blank" href="https://maps.app.goo.gl/rUX7h7EbkYUx7F7RA" rel="noreferrer"
                        >Av. Nossa Sra. da Conceição 263, Marechal Deodoro - AL, Brasil 57160-000</a>
                    </div>
                </div>
                <div class="social">
                    <a target="_blank" href="https://airbnb.com/h/espacogouvea" rel="noreferrer"
                    ><img src="./assets/icons/airbnb.png" alt="airbnb"
                        /></a>
                    <a target="_blank" href="https://instagram.com/espacogouvea" rel="noreferrer"
                    ><img src="./assets/icons/instagram.png" alt="instagram"
                        /></a>
                    <a target="_blank" href="https://g.co/kgs/rJBiWAv" rel="noreferrer"
                    ><img src="./assets/icons/tripadvisor.png" alt="tripadvisor"
                        /></a>
                </div>
                <div class="creditos">
                    <a href="https://caioagralemos.com"
                    >© Caio Agra Lemos. All rights reserved</a>
                </div>
            </div>
        </>

    )
}