import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom"; // Importe 'Routes' também
import { Analytics } from '@vercel/analytics/react';

import Portugues from "./pt";
import Ingles from "./en";
import Espanhol from "./es";

const RoutesComponent = () => { // Renomeado para evitar conflitos com o import 'Routes'
    return (
        <BrowserRouter>
            <Routes> {/* 'Routes' usado corretamente aqui para envolver os componentes 'Route' */}
                <Route path="/" element={<Portugues />} exact />
                <Route path="/en" element={<Ingles />} />
                <Route path="/es" element={<Espanhol />} />
            </Routes>
            <Analytics />
        </BrowserRouter>
    );
};

export default RoutesComponent;
