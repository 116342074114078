import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';

var imagens = [
    ["./assets/diferenciais/angelica/1.jpg", "./assets/diferenciais/angelica/2.jpg", "./assets/diferenciais/angelica/3.jpg",],
    ["./assets/diferenciais/aidil/1.jpg", "./assets/diferenciais/aidil/2.jpg",],
    ["./assets/diferenciais/gourmet/1.jpg", "./assets/diferenciais/gourmet/2.jpg", "./assets/diferenciais/gourmet/3.jpg", "./assets/diferenciais/gourmet/4.jpg",],
    ["./assets/diferenciais/lazer/1.jpg", "./assets/diferenciais/lazer/2.jpg",],
];

export default function Ingles() {
    // ####### NAVBAR ########
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [drop, setDrop] = useState(false);
    const dropdownContentRef = useRef(null);
    const navbarRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        // Limpar o event listener quando o componente for desmontado
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if ((dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) && !navbarRef.current.contains(event.target)) {
                setDrop(false);
            }
        }

        if (drop) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [drop]); // Só re-executa o efeito se o valor de 'drop' mudar

    // ####### FORM ########
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [dataFimMin, setDataFimMin] = useState('');
    const [hospedes, setHospedes] = useState(null);

    useEffect(() => {
        const hoje = new Date();
        const dataFormatada = hoje.toISOString().split('T')[0];
        setDataInicio(dataFormatada); // Define a data de hoje como valor inicial para dataInicio
        setDataFimMin(dataFormatada); // Define a data mínima para dataFim
    }, []);

    const handleDataInicioChange = (event) => {
        const novaDataInicio = event.target.value;
        setDataInicio(novaDataInicio);

        if (novaDataInicio) {
            const data = new Date(novaDataInicio);
            data.setDate(data.getDate() + 2);
            const novaDataFimMin = data.toISOString().split('T')[0];
            setDataFimMin(novaDataFimMin);
        }
    };

    const handleHospedesChange = (evt) => {
        setHospedes(evt.target.value);
    }

    const searchButton = (evt) => {
        evt.preventDefault();
        window.open(`https://www.airbnb.com.br/book/stays/1067473187464322078?checkin=${dataInicio}&numberOfGuests=${hospedes}&numberOfAdults=${hospedes}&checkout=${dataFim}&guestCurrency=BRL&productId=1067473187464322078&isWorkTrip=false&numberOfChildren=0&numberOfInfants=0&numberOfPets=0&selectedCancellationPolicyId=51&code=HMBH84AQJA&orderId=1071119166320408747`, '_blank')
    }

    const whatsappButton = (evt) => {
        evt.preventDefault();
        window.open("https://wa.me/558282074393", '_blank')
    }

    // ####### MODAL ########
    const [showModal, setShowModal] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [slideIndex, setSlideIndex] = useState(1);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.className === 'modal') {
                setShowModal(false);
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const openModal = (id) => {
        setCurrentImages(imagens[id]);
        setSlideIndex(1);
        setShowModal(true);
    };

    const changeSlide = (n) => {
        let newIndex = slideIndex + n;
        if (newIndex < 1) {
            newIndex = currentImages.length;
        } else if (newIndex > currentImages.length) {
            newIndex = 1;
        }
        setSlideIndex(newIndex);
    };

    return (
        <>
            <div class="background"></div>
            <div class="overlay"></div>

            <nav class="navbar" ref={navbarRef}>
                <Link class="nav-logo scrollLink" to="background"
                    smooth={true} duration={500} offset={-75}
                ><img class="nav-logo-img" src="./assets/nome.svg" alt="logo"
                    /></Link>
                {windowWidth > 900 ? (
                    <div class="nav-options">
                        <ul class="nav-options">
                            <li><Link class="scrollLink" to="reservas" smooth={true} duration={500} offset={-75}>RESERVATIONS</Link></li>
                            <li><Link class="scrollLink" to="o-espaco" smooth={true} duration={500} offset={-75}>ABOUT</Link></li>
                            <li><Link class="scrollLink" to="diferenciais" smooth={true} duration={500} offset={-75}>DIFFERENTIALS</Link></li>
                            <li><Link class="scrollLink" to="massagueira" smooth={true} duration={500} offset={-75}>MASSAGUEIRA</Link></li>
                            <li><Link class="scrollLink" to="contato" smooth={true} duration={500} offset={-75}>CONTACT</Link></li>
                        </ul>
                        <div class="language-selector"><a href="/">PT</a><div>•</div><a href="/es">ES</a></div>
                    </div>
                ) : (
                    <button class="nav-btn" onClick={() => setDrop(!drop)}><img src="./assets/plus.svg" alt="expand btn" /></button>
                )}
            </nav>

            {drop ? (
                <div class="dropdown" ref={dropdownContentRef}>
                    <ul class="nav-options">
                        <li><Link class="scrollLink" to="reservas" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>RESERVATIONS</Link></li>
                        <li><Link class="scrollLink" to="o-espaco" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>ABOUT</Link></li>
                        <li><Link class="scrollLink" to="diferenciais" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>DIFFERENTIALS</Link></li>
                        <li><Link class="scrollLink" to="massagueira" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>MASSAGUEIRA</Link></li>
                        <li><Link class="scrollLink" to="contato" smooth={true} duration={500} offset={-75} onClick={() => setDrop(false)}>CONTACT</Link></li>
                        <li class='drop-lselect'><a href="/">PT</a><div>|</div><a href="/es">ES</a></li>
                    </ul>
                </div>
            ) : (<></>)}

            <div class="home" id="background"></div>

            {showModal && (
                <div className="modal" style={{ display: 'flex' }}>
                    <div className='modal-content'>
                        <div className="slideshow-container">
                            {currentImages.map((src, index) => (
                                <div key={index} className="mySlides fade" style={{ display: slideIndex === index + 1 ? 'flex' : 'none' }}>
                                    <img className="mySlides-img" src={src} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                            <button class="prev" onClick={() => changeSlide(-1)}>&#10094;</button>
                            <button class="next" onClick={() => changeSlide(1)}>&#10095;</button>
                        </div>
                    </div>
                </div>
            )}

            <div class="form" id="reservas">
                BOOK ONLINE
                <form class="f-online" onSubmit={searchButton}>
                    <div class="inputs-container">
                        <div class="col-6">
                            <label for="inicio">Arv.</label>
                            <input
                                type="date"
                                class="data"
                                name="inicio"
                                id="inicio"
                                min={dataInicio}
                                onChange={handleDataInicioChange}
                                required
                            />
                        </div>

                        <div class="col-6">
                            <label for="final">Dprt.</label>
                            <input type="date" class="data" name="fim" id="fim" min={dataFimMin} disabled={!dataInicio} onChange={(e) => setDataFim(e.target.value)} required />
                        </div>

                        <div class="col-12">
                            <label for="hospedes">Guests</label>
                            <input
                                type="number"
                                name="hospedes"
                                min={1}
                                max={19}
                                placeholder="15"
                                value={hospedes}
                                onChange={handleHospedesChange}
                                required
                            />
                        </div>
                    </div>

                    <div class="botao-online">
                        <button type="submit">SEARCH</button>
                    </div>
                </form>

                {/* BOOK WITH US
                <form class="f-whatsapp" onSubmit={whatsappButton}>
                    <button type="submit">
                        RESERVATION CHANNEL <img src="./assets/icons/zapzap.png" alt="whatsapp" />
                    </button>
                </form> */}
            </div>

            <div class="o-espaco" id="o-espaco">
                <div class="titulo">ABOUT</div>
                <div class="o-espaco-1">
                    <img
                        class="o-espaco-foto"
                        src="./assets/o-espaco/1.jpg"
                        alt="space gouvea"
                    />
                    <p>
                        Located 10km from Maceió and just 5km from the stunning Praia do
                        Francês, our 4500m² retreat is a perfect blend of comfort and
                        nature. Designed by <a className='agra-lemos' href='https://agralemos.com.br' target='_blank' rel="noreferrer">Agra Lemos Arquitetos</a>, the space features two
                        incredible houses with 7 comfortable suites, accommodating up to 20 people.
                    </p>
                </div>

                <div class="o-espaco-2">
                    <img
                        class="o-espaco-foto"
                        src="./assets/o-espaco/2.jpg"
                        alt="space gouvea"
                    />
                    <p>
                        Ideal for socializing with family and friends, our newly renovated space promises magical
                        moments and eternal memories in an environment of serenity and beauty.
                        Enjoy the charm, privacy, and connection with nature in one of
                        the most enchanting destinations.
                    </p>
                </div>
            </div>

            <div class="diferenciais" id="diferenciais">
                <div class="titulo">DIFFERENTIALS</div>
                <div class="grid">
                    <div class="diferencial">
                        <img src="./assets/diferenciais/poste.png" alt="house angelica" />
                        <div class="subtitulo">CASA ANGÉLICA</div>
                        <p>
                            This main house features 3 large suites with balconies,
                            a cozy living room with integrated dining and open kitchen,
                            support kitchen with laundry and dependency, plus an
                            inviting external space.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(0)}>learn more</button>
                    </div>
                    <div class="diferencial">
                        <img src="./assets/diferenciais/aidil.png" alt="house aidil" />
                        <div class="subtitulo">CASA AIDIL</div>
                        <p>
                            This charming secondary house offers 4 additional suites,
                            providing privacy and comfort, complemented by a beautiful
                            external space.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(1)}>learn more</button>
                    </div>
                    <div class="diferencial">
                        <img
                            src="./assets/diferenciais/espacogourmet.png"
                            alt="gourmet area"
                        />
                        <div class="subtitulo">GOURMET AREA</div>
                        <p>
                            This perfect socializing environment features a gourmet area,
                            modern barbecue, and a cozy social space.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(2)}>learn more</button>
                    </div>
                    <div class="diferencial">
                        <img src="./assets/diferenciais/lazer.png" alt="leisure" />
                        <div class="subtitulo">LEISURE</div>
                        <p>
                            An imposing pool with integrated jacuzzi, LED lighting,
                            and a relaxing wooden deck equipped with loungers. Additionally,
                            for sports enthusiasts, we offer a 35x20m natural grass field, ideal for friendly matches.
                        </p>
                        <button class="dif-btn" onClick={() => openModal(3)}>learn more</button>
                    </div>
                </div>
            </div>

            <div class="massagueira" id="massagueira">
                <div class="titulo">MASSAGUEIRA</div>
                <div class="grid">
                    <iframe
                        src="https://www.youtube.com/embed/tBA_bkSbM7M?si=K9mO4cXZnSc449xr"
                        title="YouTube video player"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                    <p>
                        We are in Massagueira, a neighborhood famous for its lakeside restaurants,
                        offering an unparalleled gastronomic experience with fresh seafood,
                        fished directly from the local waters. The surrounding scenery, with views
                        of the shimmering lake, complements the experience, making each meal a
                        memorable occasion. Beyond the cuisine, the neighborhood is a tapestry of
                        tranquil streets and welcoming spaces, reflecting the charm and hospitality
                        of the region.
                    </p>
                </div>
            </div>

            <div class="contato" id="contato">
                <div class="titulo">CONTACT</div>
                <div class="dados">
                    {/* <div class="reservas">
                        <img src="./assets/icons/celular.png" alt="phone" /> <b>Reservations</b> <a target="_blank" href="https://wa.me/558282074393" rel="noreferrer"
                        >+55 (82) 98207-4393</a>
                    </div> */}
                    <div class="localizacao">
                        <img src="./assets/icons/pin.png" alt="location" /> <a target="_blank" href="https://maps.app.goo.gl/rUX7h7EbkYUx7F7RA" rel="noreferrer"
                        >Av. Nossa Sra. da Conceição 263, Marechal Deodoro - AL, Brazil 57160-000</a>
                    </div>
                </div>
                <div class="social">
                    <a target="_blank" href="https://airbnb.com/h/espacogouvea" rel="noreferrer"
                    ><img src="./assets/icons/airbnb.png" alt="airbnb"
                        /></a>
                    <a target="_blank" href="https://instagram.com/espacogouvea" rel="noreferrer"
                    ><img src="./assets/icons/instagram.png" alt="instagram"
                        /></a>
                    <a target="_blank" href="https://g.co/kgs/rJBiWAv" rel="noreferrer"
                    ><img src="./assets/icons/tripadvisor.png" alt="tripadvisor"
                        /></a>
                </div>
                <div class="creditos">
                    <a href="https://caioagralemos.com"
                    >© Caio Agra Lemos. All rights reserved</a>
                </div>
            </div>
        </>

    )
}